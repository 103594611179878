.alpha-primary {
    background-color: #e3f2fd
}

.text-primary {
    color: #2196f3
}

.text-primary[href]:focus, .text-primary[href]:hover {
    color: #0c83e2
}

.bg-primary {
    background-color: #2196f3
}

.border-primary {
    border-color: #2196f3
}

.border-top-primary {
    border-top-color: #2196f3
}

.border-bottom-primary {
    border-bottom-color: #2196f3
}

.border-left-primary {
    border-left-color: #2196f3
}

.border-right-primary {
    border-right-color: #2196f3
}

.text-primary-300 {
    color: #64b5f6
}

.text-primary-300[href]:focus, .text-primary-300[href]:hover {
    color: #40a4f4
}

.bg-primary-300 {
    background-color: #64b5f6
}

.border-primary-300 {
    border-color: #64b5f6
}

.border-top-primary-300 {
    border-top-color: #64b5f6
}

.border-bottom-primary-300 {
    border-bottom-color: #64b5f6
}

.border-left-primary-300 {
    border-left-color: #64b5f6
}

.border-right-primary-300 {
    border-right-color: #64b5f6
}

.text-primary-400 {
    color: #42a5f5
}

.text-primary-400[href]:focus, .text-primary-400[href]:hover {
    color: #1e94f3
}

.bg-primary-400 {
    background-color: #42a5f5
}

.border-primary-400 {
    border-color: #42a5f5
}

.border-top-primary-400 {
    border-top-color: #42a5f5
}

.border-bottom-primary-400 {
    border-bottom-color: #42a5f5
}

.border-left-primary-400 {
    border-left-color: #42a5f5
}

.border-right-primary-400 {
    border-right-color: #42a5f5
}

.text-primary-600 {
    color: #1e88e5
}

.text-primary-600[href]:focus, .text-primary-600[href]:hover {
    color: #1774c6
}

.bg-primary-600 {
    background-color: #1e88e5
}

.border-primary-600 {
    border-color: #1e88e5
}

.border-top-primary-600 {
    border-top-color: #1e88e5
}

.border-bottom-primary-600 {
    border-bottom-color: #1e88e5
}

.border-left-primary-600 {
    border-left-color: #1e88e5
}

.border-right-primary-600 {
    border-right-color: #1e88e5
}

.text-primary-700 {
    color: #1976d2
}

.text-primary-700[href]:focus, .text-primary-700[href]:hover {
    color: #1563b0
}

.bg-primary-700 {
    background-color: #1976d2
}

.border-primary-700 {
    border-color: #1976d2
}

.border-top-primary-700 {
    border-top-color: #1976d2
}

.border-bottom-primary-700 {
    border-bottom-color: #1976d2
}

.border-left-primary-700 {
    border-left-color: #1976d2
}

.border-right-primary-700 {
    border-right-color: #1976d2
}

.text-primary-800 {
    color: #1565c0
}

.text-primary-800[href]:focus, .text-primary-800[href]:hover {
    color: #11539e
}

.bg-primary-800 {
    background-color: #1565c0
}

.border-primary-800 {
    border-color: #1565c0
}

.border-top-primary-800 {
    border-top-color: #1565c0
}

.border-bottom-primary-800 {
    border-bottom-color: #1565c0
}

.border-left-primary-800 {
    border-left-color: #1565c0
}

.border-right-primary-800 {
    border-right-color: #1565c0
}

.alpha-danger {
    background-color: #ffebee
}

.text-danger {
    color: #f44336
}

.text-danger[href]:focus, .text-danger[href]:hover {
    color: #f22112
}

.bg-danger {
    background-color: #f44336
}

.border-danger {
    border-color: #f44336
}

.border-top-danger {
    border-top-color: #f44336
}

.border-bottom-danger {
    border-bottom-color: #f44336
}

.border-left-danger {
    border-left-color: #f44336
}

.border-right-danger {
    border-right-color: #f44336
}

.text-danger-300 {
    color: #e57373
}

.text-danger-300[href]:focus, .text-danger-300[href]:hover {
    color: #df5353
}

.bg-danger-300 {
    background-color: #e57373
}

.border-danger-300 {
    border-color: #e57373
}

.border-top-danger-300 {
    border-top-color: #e57373
}

.border-bottom-danger-300 {
    border-bottom-color: #e57373
}

.border-left-danger-300 {
    border-left-color: #e57373
}

.border-right-danger-300 {
    border-right-color: #e57373
}

.text-danger-400 {
    color: #ef5350
}

.text-danger-400[href]:focus, .text-danger-400[href]:hover {
    color: #ec312d
}

.bg-danger-400 {
    background-color: #ef5350
}

.border-danger-400 {
    border-color: #ef5350
}

.border-top-danger-400 {
    border-top-color: #ef5350
}

.border-bottom-danger-400 {
    border-bottom-color: #ef5350
}

.border-left-danger-400 {
    border-left-color: #ef5350
}

.border-right-danger-400 {
    border-right-color: #ef5350
}

.text-danger-600 {
    color: #e53935
}

.text-danger-600[href]:focus, .text-danger-600[href]:hover {
    color: #d8201c
}

.bg-danger-600 {
    background-color: #e53935
}

.border-danger-600 {
    border-color: #e53935
}

.border-top-danger-600 {
    border-top-color: #e53935
}

.border-bottom-danger-600 {
    border-bottom-color: #e53935
}

.border-left-danger-600 {
    border-left-color: #e53935
}

.border-right-danger-600 {
    border-right-color: #e53935
}

.text-danger-700 {
    color: #d32f2f
}

.text-danger-700[href]:focus, .text-danger-700[href]:hover {
    color: #b52626
}

.bg-danger-700 {
    background-color: #d32f2f
}

.border-danger-700 {
    border-color: #d32f2f
}

.border-top-danger-700 {
    border-top-color: #d32f2f
}

.border-bottom-danger-700 {
    border-bottom-color: #d32f2f
}

.border-left-danger-700 {
    border-left-color: #d32f2f
}

.border-right-danger-700 {
    border-right-color: #d32f2f
}

.text-danger-800 {
    color: #c62828
}

.text-danger-800[href]:focus, .text-danger-800[href]:hover {
    color: #a62222
}

.bg-danger-800 {
    background-color: #c62828
}

.border-danger-800 {
    border-color: #c62828
}

.border-top-danger-800 {
    border-top-color: #c62828
}

.border-bottom-danger-800 {
    border-bottom-color: #c62828
}

.border-left-danger-800 {
    border-left-color: #c62828
}

.border-right-danger-800 {
    border-right-color: #c62828
}

.alpha-success {
    background-color: #e8f5e9
}

.text-success {
    color: #4caf50
}

.text-success[href]:focus, .text-success[href]:hover {
    color: #409444
}

.bg-success {
    background-color: #4caf50
}

.border-success {
    border-color: #4caf50
}

.border-top-success {
    border-top-color: #4caf50
}

.border-bottom-success {
    border-bottom-color: #4caf50
}

.border-left-success {
    border-left-color: #4caf50
}

.border-right-success {
    border-right-color: #4caf50
}

.text-success-300 {
    color: #81c784
}

.text-success-300[href]:focus, .text-success-300[href]:hover {
    color: #67bb6a
}

.bg-success-300 {
    background-color: #81c784
}

.border-success-300 {
    border-color: #81c784
}

.border-top-success-300 {
    border-top-color: #81c784
}

.border-bottom-success-300 {
    border-bottom-color: #81c784
}

.border-left-success-300 {
    border-left-color: #81c784
}

.border-right-success-300 {
    border-right-color: #81c784
}

.text-success-400 {
    color: #66bb6a
}

.text-success-400[href]:focus, .text-success-400[href]:hover {
    color: #4dae52
}

.bg-success-400 {
    background-color: #66bb6a
}

.border-success-400 {
    border-color: #66bb6a
}

.border-top-success-400 {
    border-top-color: #66bb6a
}

.border-bottom-success-400 {
    border-bottom-color: #66bb6a
}

.border-left-success-400 {
    border-left-color: #66bb6a
}

.border-right-success-400 {
    border-right-color: #66bb6a
}

.text-success-600 {
    color: #43a047
}

.text-success-600[href]:focus, .text-success-600[href]:hover {
    color: #38853b
}

.bg-success-600 {
    background-color: #43a047
}

.border-success-600 {
    border-color: #43a047
}

.border-top-success-600 {
    border-top-color: #43a047
}

.border-bottom-success-600 {
    border-bottom-color: #43a047
}

.border-left-success-600 {
    border-left-color: #43a047
}

.border-right-success-600 {
    border-right-color: #43a047
}

.text-success-700 {
    color: #388e3c
}

.text-success-700[href]:focus, .text-success-700[href]:hover {
    color: #2d7330
}

.bg-success-700 {
    background-color: #388e3c
}

.border-success-700 {
    border-color: #388e3c
}

.border-top-success-700 {
    border-top-color: #388e3c
}

.border-bottom-success-700 {
    border-bottom-color: #388e3c
}

.border-left-success-700 {
    border-left-color: #388e3c
}

.border-right-success-700 {
    border-right-color: #388e3c
}

.text-success-800 {
    color: #2e7d32
}

.text-success-800[href]:focus, .text-success-800[href]:hover {
    color: #246127
}

.bg-success-800 {
    background-color: #2e7d32
}

.border-success-800 {
    border-color: #2e7d32
}

.border-top-success-800 {
    border-top-color: #2e7d32
}

.border-bottom-success-800 {
    border-bottom-color: #2e7d32
}

.border-left-success-800 {
    border-left-color: #2e7d32
}

.border-right-success-800 {
    border-right-color: #2e7d32
}

.alpha-warning {
    background-color: #fbe9e7
}

.text-warning {
    color: #ff5722
}

.text-warning[href]:focus, .text-warning[href]:hover {
    color: #fb3c00
}

.bg-warning {
    background-color: #ff5722
}

.border-warning {
    border-color: #ff5722
}

.border-top-warning {
    border-top-color: #ff5722
}

.border-bottom-warning {
    border-bottom-color: #ff5722
}

.border-left-warning {
    border-left-color: #ff5722
}

.border-right-warning {
    border-right-color: #ff5722
}

.text-warning-300 {
    color: #ff8a65
}

.text-warning-300[href]:focus, .text-warning-300[href]:hover {
    color: #ff6d3f
}

.bg-warning-300 {
    background-color: #ff8a65
}

.border-warning-300 {
    border-color: #ff8a65
}

.border-top-warning-300 {
    border-top-color: #ff8a65
}

.border-bottom-warning-300 {
    border-bottom-color: #ff8a65
}

.border-left-warning-300 {
    border-left-color: #ff8a65
}

.border-right-warning-300 {
    border-right-color: #ff8a65
}

.text-warning-400 {
    color: #ff7043
}

.text-warning-400[href]:focus, .text-warning-400[href]:hover {
    color: #ff531d
}

.bg-warning-400 {
    background-color: #ff7043
}

.border-warning-400 {
    border-color: #ff7043
}

.border-top-warning-400 {
    border-top-color: #ff7043
}

.border-bottom-warning-400 {
    border-bottom-color: #ff7043
}

.border-left-warning-400 {
    border-left-color: #ff7043
}

.border-right-warning-400 {
    border-right-color: #ff7043
}

.text-warning-600 {
    color: #f4511e
}

.text-warning-600[href]:focus, .text-warning-600[href]:hover {
    color: #e13e0b
}

.bg-warning-600 {
    background-color: #f4511e
}

.border-warning-600 {
    border-color: #f4511e
}

.border-top-warning-600 {
    border-top-color: #f4511e
}

.border-bottom-warning-600 {
    border-bottom-color: #f4511e
}

.border-left-warning-600 {
    border-left-color: #f4511e
}

.border-right-warning-600 {
    border-right-color: #f4511e
}

.text-warning-700 {
    color: #e64a19
}

.text-warning-700[href]:focus, .text-warning-700[href]:hover {
    color: #c43f15
}

.bg-warning-700 {
    background-color: #e64a19
}

.border-warning-700 {
    border-color: #e64a19
}

.border-top-warning-700 {
    border-top-color: #e64a19
}

.border-bottom-warning-700 {
    border-bottom-color: #e64a19
}

.border-left-warning-700 {
    border-left-color: #e64a19
}

.border-right-warning-700 {
    border-right-color: #e64a19
}

.text-warning-800 {
    color: #d84315
}

.text-warning-800[href]:focus, .text-warning-800[href]:hover {
    color: #b53812
}

.bg-warning-800 {
    background-color: #d84315
}

.border-warning-800 {
    border-color: #d84315
}

.border-top-warning-800 {
    border-top-color: #d84315
}

.border-bottom-warning-800 {
    border-bottom-color: #d84315
}

.border-left-warning-800 {
    border-left-color: #d84315
}

.border-right-warning-800 {
    border-right-color: #d84315
}

.alpha-info {
    background-color: #e0f7fa
}

.text-info {
    color: #00bcd4
}

.text-info[href]:focus, .text-info[href]:hover {
    color: #009aae
}

.bg-info {
    background-color: #00bcd4
}

.border-info {
    border-color: #00bcd4
}

.border-top-info {
    border-top-color: #00bcd4
}

.border-bottom-info {
    border-bottom-color: #00bcd4
}

.border-left-info {
    border-left-color: #00bcd4
}

.border-right-info {
    border-right-color: #00bcd4
}

.text-info-300 {
    color: #4dd0e1
}

.text-info-300[href]:focus, .text-info-300[href]:hover {
    color: #2cc7db
}

.bg-info-300 {
    background-color: #4dd0e1
}

.border-info-300 {
    border-color: #4dd0e1
}

.border-top-info-300 {
    border-top-color: #4dd0e1
}

.border-bottom-info-300 {
    border-bottom-color: #4dd0e1
}

.border-left-info-300 {
    border-left-color: #4dd0e1
}

.border-right-info-300 {
    border-right-color: #4dd0e1
}

.text-info-400 {
    color: #26c6da
}

.text-info-400[href]:focus, .text-info-400[href]:hover {
    color: #20a9ba
}

.bg-info-400 {
    background-color: #26c6da
}

.border-info-400 {
    border-color: #26c6da
}

.border-top-info-400 {
    border-top-color: #26c6da
}

.border-bottom-info-400 {
    border-bottom-color: #26c6da
}

.border-left-info-400 {
    border-left-color: #26c6da
}

.border-right-info-400 {
    border-right-color: #26c6da
}

.text-info-600 {
    color: #00acc1
}

.text-info-600[href]:focus, .text-info-600[href]:hover {
    color: #008a9b
}

.bg-info-600 {
    background-color: #00acc1
}

.border-info-600 {
    border-color: #00acc1
}

.border-top-info-600 {
    border-top-color: #00acc1
}

.border-bottom-info-600 {
    border-bottom-color: #00acc1
}

.border-left-info-600 {
    border-left-color: #00acc1
}

.border-right-info-600 {
    border-right-color: #00acc1
}

.text-info-700 {
    color: #0097a7
}

.text-info-700[href]:focus, .text-info-700[href]:hover {
    color: #007481
}

.bg-info-700 {
    background-color: #0097a7
}

.border-info-700 {
    border-color: #0097a7
}

.border-top-info-700 {
    border-top-color: #0097a7
}

.border-bottom-info-700 {
    border-bottom-color: #0097a7
}

.border-left-info-700 {
    border-left-color: #0097a7
}

.border-right-info-700 {
    border-right-color: #0097a7
}

.text-info-800 {
    color: #00838f
}

.text-info-800[href]:focus, .text-info-800[href]:hover {
    color: #006069
}

.bg-info-800 {
    background-color: #00838f
}

.border-info-800 {
    border-color: #00838f
}

.border-top-info-800 {
    border-top-color: #00838f
}

.border-bottom-info-800 {
    border-bottom-color: #00838f
}

.border-left-info-800 {
    border-left-color: #00838f
}

.border-right-info-800 {
    border-right-color: #00838f
}

.alpha-pink {
    background-color: #fce4ec
}

.text-pink {
    color: #e91e63
}

.text-pink[href]:focus, .text-pink[href]:hover {
    color: #cd1453
}

.bg-pink {
    background-color: #e91e63
}

.border-pink {
    border-color: #e91e63
}

.border-top-pink {
    border-top-color: #e91e63
}

.border-bottom-pink {
    border-bottom-color: #e91e63
}

.border-left-pink {
    border-left-color: #e91e63
}

.border-right-pink {
    border-right-color: #e91e63
}

.text-pink-300 {
    color: #f06292
}

.text-pink-300[href]:focus, .text-pink-300[href]:hover {
    color: #ed3f7a
}

.bg-pink-300 {
    background-color: #f06292
}

.border-pink-300 {
    border-color: #f06292
}

.border-top-pink-300 {
    border-top-color: #f06292
}

.border-bottom-pink-300 {
    border-bottom-color: #f06292
}

.border-left-pink-300 {
    border-left-color: #f06292
}

.border-right-pink-300 {
    border-right-color: #f06292
}

.text-pink-400 {
    color: #ec407a
}

.text-pink-400[href]:focus, .text-pink-400[href]:hover {
    color: #e91d62
}

.bg-pink-400 {
    background-color: #ec407a
}

.border-pink-400 {
    border-color: #ec407a
}

.border-top-pink-400 {
    border-top-color: #ec407a
}

.border-bottom-pink-400 {
    border-bottom-color: #ec407a
}

.border-left-pink-400 {
    border-left-color: #ec407a
}

.border-right-pink-400 {
    border-right-color: #ec407a
}

.text-pink-600 {
    color: #d81b60
}

.text-pink-600[href]:focus, .text-pink-600[href]:hover {
    color: #b61751
}

.bg-pink-600 {
    background-color: #d81b60
}

.border-pink-600 {
    border-color: #d81b60
}

.border-top-pink-600 {
    border-top-color: #d81b60
}

.border-bottom-pink-600 {
    border-bottom-color: #d81b60
}

.border-left-pink-600 {
    border-left-color: #d81b60
}

.border-right-pink-600 {
    border-right-color: #d81b60
}

.text-pink-700 {
    color: #c2185b
}

.text-pink-700[href]:focus, .text-pink-700[href]:hover {
    color: #a0144b
}

.bg-pink-700 {
    background-color: #c2185b
}

.border-pink-700 {
    border-color: #c2185b
}

.border-top-pink-700 {
    border-top-color: #c2185b
}

.border-bottom-pink-700 {
    border-bottom-color: #c2185b
}

.border-left-pink-700 {
    border-left-color: #c2185b
}

.border-right-pink-700 {
    border-right-color: #c2185b
}

.text-pink-800 {
    color: #ad1457
}

.text-pink-800[href]:focus, .text-pink-800[href]:hover {
    color: #8b1046
}

.bg-pink-800 {
    background-color: #ad1457
}

.border-pink-800 {
    border-color: #ad1457
}

.border-top-pink-800 {
    border-top-color: #ad1457
}

.border-bottom-pink-800 {
    border-bottom-color: #ad1457
}

.border-left-pink-800 {
    border-left-color: #ad1457
}

.border-right-pink-800 {
    border-right-color: #ad1457
}

.alpha-violet {
    background-color: #f3e5f5
}

.text-violet {
    color: #9c27b0
}

.text-violet[href]:focus, .text-violet[href]:hover {
    color: #802091
}

.bg-violet {
    background-color: #9c27b0
}

.border-violet {
    border-color: #9c27b0
}

.border-top-violet {
    border-top-color: #9c27b0
}

.border-bottom-violet {
    border-bottom-color: #9c27b0
}

.border-left-violet {
    border-left-color: #9c27b0
}

.border-right-violet {
    border-right-color: #9c27b0
}

.text-violet-300 {
    color: #ba68c8
}

.text-violet-300[href]:focus, .text-violet-300[href]:hover {
    color: #ad4cbe
}

.bg-violet-300 {
    background-color: #ba68c8
}

.border-violet-300 {
    border-color: #ba68c8
}

.border-top-violet-300 {
    border-top-color: #ba68c8
}

.border-bottom-violet-300 {
    border-bottom-color: #ba68c8
}

.border-left-violet-300 {
    border-left-color: #ba68c8
}

.border-right-violet-300 {
    border-right-color: #ba68c8
}

.text-violet-400 {
    color: #ab47bc
}

.text-violet-400[href]:focus, .text-violet-400[href]:hover {
    color: #933ba2
}

.bg-violet-400 {
    background-color: #ab47bc
}

.border-violet-400 {
    border-color: #ab47bc
}

.border-top-violet-400 {
    border-top-color: #ab47bc
}

.border-bottom-violet-400 {
    border-bottom-color: #ab47bc
}

.border-left-violet-400 {
    border-left-color: #ab47bc
}

.border-right-violet-400 {
    border-right-color: #ab47bc
}

.text-violet-600 {
    color: #8e24aa
}

.text-violet-600[href]:focus, .text-violet-600[href]:hover {
    color: #741d8a
}

.bg-violet-600 {
    background-color: #8e24aa
}

.border-violet-600 {
    border-color: #8e24aa
}

.border-top-violet-600 {
    border-top-color: #8e24aa
}

.border-bottom-violet-600 {
    border-bottom-color: #8e24aa
}

.border-left-violet-600 {
    border-left-color: #8e24aa
}

.border-right-violet-600 {
    border-right-color: #8e24aa
}

.text-violet-700 {
    color: #7b1fa2
}

.text-violet-700[href]:focus, .text-violet-700[href]:hover {
    color: #631982
}

.bg-violet-700 {
    background-color: #7b1fa2
}

.border-violet-700 {
    border-color: #7b1fa2
}

.border-top-violet-700 {
    border-top-color: #7b1fa2
}

.border-bottom-violet-700 {
    border-bottom-color: #7b1fa2
}

.border-left-violet-700 {
    border-left-color: #7b1fa2
}

.border-right-violet-700 {
    border-right-color: #7b1fa2
}

.text-violet-800 {
    color: #6a1b9a
}

.text-violet-800[href]:focus, .text-violet-800[href]:hover {
    color: #541579
}

.bg-violet-800 {
    background-color: #6a1b9a
}

.border-violet-800 {
    border-color: #6a1b9a
}

.border-top-violet-800 {
    border-top-color: #6a1b9a
}

.border-bottom-violet-800 {
    border-bottom-color: #6a1b9a
}

.border-left-violet-800 {
    border-left-color: #6a1b9a
}

.border-right-violet-800 {
    border-right-color: #6a1b9a
}

.alpha-purple {
    background-color: #ede7f6
}

.text-purple {
    color: #673ab7
}

.text-purple[href]:focus, .text-purple[href]:hover {
    color: #57319a
}

.bg-purple {
    background-color: #673ab7
}

.border-purple {
    border-color: #673ab7
}

.border-top-purple {
    border-top-color: #673ab7
}

.border-bottom-purple {
    border-bottom-color: #673ab7
}

.border-left-purple {
    border-left-color: #673ab7
}

.border-right-purple {
    border-right-color: #673ab7
}

.text-purple-300 {
    color: #9575cd
}

.text-purple-300[href]:focus, .text-purple-300[href]:hover {
    color: #7f59c3
}

.bg-purple-300 {
    background-color: #9575cd
}

.border-purple-300 {
    border-color: #9575cd
}

.border-top-purple-300 {
    border-top-color: #9575cd
}

.border-bottom-purple-300 {
    border-bottom-color: #9575cd
}

.border-left-purple-300 {
    border-left-color: #9575cd
}

.border-right-purple-300 {
    border-right-color: #9575cd
}

.text-purple-400 {
    color: #7e57c2
}

.text-purple-400[href]:focus, .text-purple-400[href]:hover {
    color: #6a41b2
}

.bg-purple-400 {
    background-color: #7e57c2
}

.border-purple-400 {
    border-color: #7e57c2
}

.border-top-purple-400 {
    border-top-color: #7e57c2
}

.border-bottom-purple-400 {
    border-bottom-color: #7e57c2
}

.border-left-purple-400 {
    border-left-color: #7e57c2
}

.border-right-purple-400 {
    border-right-color: #7e57c2
}

.text-purple-600 {
    color: #5e35b1
}

.text-purple-600[href]:focus, .text-purple-600[href]:hover {
    color: #4e2c94
}

.bg-purple-600 {
    background-color: #5e35b1
}

.border-purple-600 {
    border-color: #5e35b1
}

.border-top-purple-600 {
    border-top-color: #5e35b1
}

.border-bottom-purple-600 {
    border-bottom-color: #5e35b1
}

.border-left-purple-600 {
    border-left-color: #5e35b1
}

.border-right-purple-600 {
    border-right-color: #5e35b1
}

.text-purple-700 {
    color: #512da8
}

.text-purple-700[href]:focus, .text-purple-700[href]:hover {
    color: #42258a
}

.bg-purple-700 {
    background-color: #512da8
}

.border-purple-700 {
    border-color: #512da8
}

.border-top-purple-700 {
    border-top-color: #512da8
}

.border-bottom-purple-700 {
    border-bottom-color: #512da8
}

.border-left-purple-700 {
    border-left-color: #512da8
}

.border-right-purple-700 {
    border-right-color: #512da8
}

.text-purple-800 {
    color: #4527a0
}

.text-purple-800[href]:focus, .text-purple-800[href]:hover {
    color: #382081
}

.bg-purple-800 {
    background-color: #4527a0
}

.border-purple-800 {
    border-color: #4527a0
}

.border-top-purple-800 {
    border-top-color: #4527a0
}

.border-bottom-purple-800 {
    border-bottom-color: #4527a0
}

.border-left-purple-800 {
    border-left-color: #4527a0
}

.border-right-purple-800 {
    border-right-color: #4527a0
}

.alpha-indigo {
    background-color: #e8eaf6
}

.text-indigo {
    color: #3f51b5
}

.text-indigo[href]:focus, .text-indigo[href]:hover {
    color: #354499
}

.bg-indigo {
    background-color: #3f51b5
}

.border-indigo {
    border-color: #3f51b5
}

.border-top-indigo {
    border-top-color: #3f51b5
}

.border-bottom-indigo {
    border-bottom-color: #3f51b5
}

.border-left-indigo {
    border-left-color: #3f51b5
}

.border-right-indigo {
    border-right-color: #3f51b5
}

.text-indigo-300 {
    color: #7986cb
}

.text-indigo-300[href]:focus, .text-indigo-300[href]:hover {
    color: #5d6dc0
}

.bg-indigo-300 {
    background-color: #7986cb
}

.border-indigo-300 {
    border-color: #7986cb
}

.border-top-indigo-300 {
    border-top-color: #7986cb
}

.border-bottom-indigo-300 {
    border-bottom-color: #7986cb
}

.border-left-indigo-300 {
    border-left-color: #7986cb
}

.border-right-indigo-300 {
    border-right-color: #7986cb
}

.text-indigo-400 {
    color: #5c6bc0
}

.text-indigo-400[href]:focus, .text-indigo-400[href]:hover {
    color: #4555b1
}

.bg-indigo-400 {
    background-color: #5c6bc0
}

.border-indigo-400 {
    border-color: #5c6bc0
}

.border-top-indigo-400 {
    border-top-color: #5c6bc0
}

.border-bottom-indigo-400 {
    border-bottom-color: #5c6bc0
}

.border-left-indigo-400 {
    border-left-color: #5c6bc0
}

.border-right-indigo-400 {
    border-right-color: #5c6bc0
}

.text-indigo-600 {
    color: #3949ab
}

.text-indigo-600[href]:focus, .text-indigo-600[href]:hover {
    color: #2f3d8e
}

.bg-indigo-600 {
    background-color: #3949ab
}

.border-indigo-600 {
    border-color: #3949ab
}

.border-top-indigo-600 {
    border-top-color: #3949ab
}

.border-bottom-indigo-600 {
    border-bottom-color: #3949ab
}

.border-left-indigo-600 {
    border-left-color: #3949ab
}

.border-right-indigo-600 {
    border-right-color: #3949ab
}

.text-indigo-700 {
    color: #303f9f
}

.text-indigo-700[href]:focus, .text-indigo-700[href]:hover {
    color: #273382
}

.bg-indigo-700 {
    background-color: #303f9f
}

.border-indigo-700 {
    border-color: #303f9f
}

.border-top-indigo-700 {
    border-top-color: #303f9f
}

.border-bottom-indigo-700 {
    border-bottom-color: #303f9f
}

.border-left-indigo-700 {
    border-left-color: #303f9f
}

.border-right-indigo-700 {
    border-right-color: #303f9f
}

.text-indigo-800 {
    color: #283593
}

.text-indigo-800[href]:focus, .text-indigo-800[href]:hover {
    color: #202a75
}

.bg-indigo-800 {
    background-color: #283593
}

.border-indigo-800 {
    border-color: #283593
}

.border-top-indigo-800 {
    border-top-color: #283593
}

.border-bottom-indigo-800 {
    border-bottom-color: #283593
}

.border-left-indigo-800 {
    border-left-color: #283593
}

.border-right-indigo-800 {
    border-right-color: #283593
}

.alpha-blue {
    background-color: #e1f5fe
}

.text-blue {
    color: #03a9f4
}

.text-blue[href]:focus, .text-blue[href]:hover {
    color: #038fce
}

.bg-blue {
    background-color: #03a9f4
}

.border-blue {
    border-color: #03a9f4
}

.border-top-blue {
    border-top-color: #03a9f4
}

.border-bottom-blue {
    border-bottom-color: #03a9f4
}

.border-left-blue {
    border-left-color: #03a9f4
}

.border-right-blue {
    border-right-color: #03a9f4
}

.text-blue-300 {
    color: #4fc3f7
}

.text-blue-300[href]:focus, .text-blue-300[href]:hover {
    color: #2ab7f5
}

.bg-blue-300 {
    background-color: #4fc3f7
}

.border-blue-300 {
    border-color: #4fc3f7
}

.border-top-blue-300 {
    border-top-color: #4fc3f7
}

.border-bottom-blue-300 {
    border-bottom-color: #4fc3f7
}

.border-left-blue-300 {
    border-left-color: #4fc3f7
}

.border-right-blue-300 {
    border-right-color: #4fc3f7
}

.text-blue-400 {
    color: #29b6f6
}

.text-blue-400[href]:focus, .text-blue-400[href]:hover {
    color: #0aa7ef
}

.bg-blue-400 {
    background-color: #29b6f6
}

.border-blue-400 {
    border-color: #29b6f6
}

.border-top-blue-400 {
    border-top-color: #29b6f6
}

.border-bottom-blue-400 {
    border-bottom-color: #29b6f6
}

.border-left-blue-400 {
    border-left-color: #29b6f6
}

.border-right-blue-400 {
    border-right-color: #29b6f6
}

.text-blue-600 {
    color: #039be5
}

.text-blue-600[href]:focus, .text-blue-600[href]:hover {
    color: #0381bf
}

.bg-blue-600 {
    background-color: #039be5
}

.border-blue-600 {
    border-color: #039be5
}

.border-top-blue-600 {
    border-top-color: #039be5
}

.border-bottom-blue-600 {
    border-bottom-color: #039be5
}

.border-left-blue-600 {
    border-left-color: #039be5
}

.border-right-blue-600 {
    border-right-color: #039be5
}

.text-blue-700 {
    color: #0288d1
}

.text-blue-700[href]:focus, .text-blue-700[href]:hover {
    color: #026fab
}

.bg-blue-700 {
    background-color: #0288d1
}

.border-blue-700 {
    border-color: #0288d1
}

.border-top-blue-700 {
    border-top-color: #0288d1
}

.border-bottom-blue-700 {
    border-bottom-color: #0288d1
}

.border-left-blue-700 {
    border-left-color: #0288d1
}

.border-right-blue-700 {
    border-right-color: #0288d1
}

.text-blue-800 {
    color: #0277bd
}

.text-blue-800[href]:focus, .text-blue-800[href]:hover {
    color: #025f97
}

.bg-blue-800 {
    background-color: #0277bd
}

.border-blue-800 {
    border-color: #0277bd
}

.border-top-blue-800 {
    border-top-color: #0277bd
}

.border-bottom-blue-800 {
    border-bottom-color: #0277bd
}

.border-left-blue-800 {
    border-left-color: #0277bd
}

.border-right-blue-800 {
    border-right-color: #0277bd
}

.alpha-teal {
    background-color: #e0f2f1
}

.text-teal {
    color: #009688
}

.text-teal[href]:focus, .text-teal[href]:hover {
    color: #007065
}

.bg-teal {
    background-color: #009688
}

.border-teal {
    border-color: #009688
}

.border-top-teal {
    border-top-color: #009688
}

.border-bottom-teal {
    border-bottom-color: #009688
}

.border-left-teal {
    border-left-color: #009688
}

.border-right-teal {
    border-right-color: #009688
}

.text-teal-300 {
    color: #4db6ac
}

.text-teal-300[href]:focus, .text-teal-300[href]:hover {
    color: #409d94
}

.bg-teal-300 {
    background-color: #4db6ac
}

.border-teal-300 {
    border-color: #4db6ac
}

.border-top-teal-300 {
    border-top-color: #4db6ac
}

.border-bottom-teal-300 {
    border-bottom-color: #4db6ac
}

.border-left-teal-300 {
    border-left-color: #4db6ac
}

.border-right-teal-300 {
    border-right-color: #4db6ac
}

.text-teal-400 {
    color: #26a69a
}

.text-teal-400[href]:focus, .text-teal-400[href]:hover {
    color: #1f877d
}

.bg-teal-400 {
    background-color: #26a69a
}

.border-teal-400 {
    border-color: #26a69a
}

.border-top-teal-400 {
    border-top-color: #26a69a
}

.border-bottom-teal-400 {
    border-bottom-color: #26a69a
}

.border-left-teal-400 {
    border-left-color: #26a69a
}

.border-right-teal-400 {
    border-right-color: #26a69a
}

.text-teal-600 {
    color: #00897b
}

.text-teal-600[href]:focus, .text-teal-600[href]:hover {
    color: #006359
}

.bg-teal-600 {
    background-color: #00897b
}

.border-teal-600 {
    border-color: #00897b
}

.border-top-teal-600 {
    border-top-color: #00897b
}

.border-bottom-teal-600 {
    border-bottom-color: #00897b
}

.border-left-teal-600 {
    border-left-color: #00897b
}

.border-right-teal-600 {
    border-right-color: #00897b
}

.text-teal-700 {
    color: #00796b
}

.text-teal-700[href]:focus, .text-teal-700[href]:hover {
    color: #005349
}

.bg-teal-700 {
    background-color: #00796b
}

.border-teal-700 {
    border-color: #00796b
}

.border-top-teal-700 {
    border-top-color: #00796b
}

.border-bottom-teal-700 {
    border-bottom-color: #00796b
}

.border-left-teal-700 {
    border-left-color: #00796b
}

.border-right-teal-700 {
    border-right-color: #00796b
}

.text-teal-800 {
    color: #00695c
}

.text-teal-800[href]:focus, .text-teal-800[href]:hover {
    color: #00433a
}

.bg-teal-800 {
    background-color: #00695c
}

.border-teal-800 {
    border-color: #00695c
}

.border-top-teal-800 {
    border-top-color: #00695c
}

.border-bottom-teal-800 {
    border-bottom-color: #00695c
}

.border-left-teal-800 {
    border-left-color: #00695c
}

.border-right-teal-800 {
    border-right-color: #00695c
}

.alpha-green {
    background-color: #f1f8e9
}

.text-green {
    color: #8bc34a
}

.text-green[href]:focus, .text-green[href]:hover {
    color: #78ad39
}

.bg-green {
    background-color: #8bc34a
}

.border-green {
    border-color: #8bc34a
}

.border-top-green {
    border-top-color: #8bc34a
}

.border-bottom-green {
    border-bottom-color: #8bc34a
}

.border-left-green {
    border-left-color: #8bc34a
}

.border-right-green {
    border-right-color: #8bc34a
}

.text-green-300 {
    color: #aed581
}

.text-green-300[href]:focus, .text-green-300[href]:hover {
    color: #9ccb64
}

.bg-green-300 {
    background-color: #aed581
}

.border-green-300 {
    border-color: #aed581
}

.border-top-green-300 {
    border-top-color: #aed581
}

.border-bottom-green-300 {
    border-bottom-color: #aed581
}

.border-left-green-300 {
    border-left-color: #aed581
}

.border-right-green-300 {
    border-right-color: #aed581
}

.text-green-400 {
    color: #9ccc65
}

.text-green-400[href]:focus, .text-green-400[href]:hover {
    color: #8ac248
}

.bg-green-400 {
    background-color: #9ccc65
}

.border-green-400 {
    border-color: #9ccc65
}

.border-top-green-400 {
    border-top-color: #9ccc65
}

.border-bottom-green-400 {
    border-bottom-color: #9ccc65
}

.border-left-green-400 {
    border-left-color: #9ccc65
}

.border-right-green-400 {
    border-right-color: #9ccc65
}

.text-green-600 {
    color: #7cb342
}

.text-green-600[href]:focus, .text-green-600[href]:hover {
    color: #699738
}

.bg-green-600 {
    background-color: #7cb342
}

.border-green-600 {
    border-color: #7cb342
}

.border-top-green-600 {
    border-top-color: #7cb342
}

.border-bottom-green-600 {
    border-bottom-color: #7cb342
}

.border-left-green-600 {
    border-left-color: #7cb342
}

.border-right-green-600 {
    border-right-color: #7cb342
}

.text-green-700 {
    color: #689f38
}

.text-green-700[href]:focus, .text-green-700[href]:hover {
    color: #55832e
}

.bg-green-700 {
    background-color: #689f38
}

.border-green-700 {
    border-color: #689f38
}

.border-top-green-700 {
    border-top-color: #689f38
}

.border-bottom-green-700 {
    border-bottom-color: #689f38
}

.border-left-green-700 {
    border-left-color: #689f38
}

.border-right-green-700 {
    border-right-color: #689f38
}

.text-green-800 {
    color: #558b2f
}

.text-green-800[href]:focus, .text-green-800[href]:hover {
    color: #446e25
}

.bg-green-800 {
    background-color: #558b2f
}

.border-green-800 {
    border-color: #558b2f
}

.border-top-green-800 {
    border-top-color: #558b2f
}

.border-bottom-green-800 {
    border-bottom-color: #558b2f
}

.border-left-green-800 {
    border-left-color: #558b2f
}

.border-right-green-800 {
    border-right-color: #558b2f
}

.alpha-orange {
    background-color: #fff3e0
}

.text-orange {
    color: #ff9800
}

.text-orange[href]:focus, .text-orange[href]:hover {
    color: #d98100
}

.bg-orange {
    background-color: #ff9800
}

.border-orange {
    border-color: #ff9800
}

.border-top-orange {
    border-top-color: #ff9800
}

.border-bottom-orange {
    border-bottom-color: #ff9800
}

.border-left-orange {
    border-left-color: #ff9800
}

.border-right-orange {
    border-right-color: #ff9800
}

.text-orange-300 {
    color: #ffb74d
}

.text-orange-300[href]:focus, .text-orange-300[href]:hover {
    color: #ffa827
}

.bg-orange-300 {
    background-color: #ffb74d
}

.border-orange-300 {
    border-color: #ffb74d
}

.border-top-orange-300 {
    border-top-color: #ffb74d
}

.border-bottom-orange-300 {
    border-bottom-color: #ffb74d
}

.border-left-orange-300 {
    border-left-color: #ffb74d
}

.border-right-orange-300 {
    border-right-color: #ffb74d
}

.text-orange-400 {
    color: #ffa726
}

.text-orange-400[href]:focus, .text-orange-400[href]:hover {
    color: #ff9700
}

.bg-orange-400 {
    background-color: #ffa726
}

.border-orange-400 {
    border-color: #ffa726
}

.border-top-orange-400 {
    border-top-color: #ffa726
}

.border-bottom-orange-400 {
    border-bottom-color: #ffa726
}

.border-left-orange-400 {
    border-left-color: #ffa726
}

.border-right-orange-400 {
    border-right-color: #ffa726
}

.text-orange-600 {
    color: #fb8c00
}

.text-orange-600[href]:focus, .text-orange-600[href]:hover {
    color: #d57700
}

.bg-orange-600 {
    background-color: #fb8c00
}

.border-orange-600 {
    border-color: #fb8c00
}

.border-top-orange-600 {
    border-top-color: #fb8c00
}

.border-bottom-orange-600 {
    border-bottom-color: #fb8c00
}

.border-left-orange-600 {
    border-left-color: #fb8c00
}

.border-right-orange-600 {
    border-right-color: #fb8c00
}

.text-orange-700 {
    color: #f57c00
}

.text-orange-700[href]:focus, .text-orange-700[href]:hover {
    color: #cf6900
}

.bg-orange-700 {
    background-color: #f57c00
}

.border-orange-700 {
    border-color: #f57c00
}

.border-top-orange-700 {
    border-top-color: #f57c00
}

.border-bottom-orange-700 {
    border-bottom-color: #f57c00
}

.border-left-orange-700 {
    border-left-color: #f57c00
}

.border-right-orange-700 {
    border-right-color: #f57c00
}

.text-orange-800 {
    color: #ef6c00
}

.text-orange-800[href]:focus, .text-orange-800[href]:hover {
    color: #c95b00
}

.bg-orange-800 {
    background-color: #ef6c00
}

.border-orange-800 {
    border-color: #ef6c00
}

.border-top-orange-800 {
    border-top-color: #ef6c00
}

.border-bottom-orange-800 {
    border-bottom-color: #ef6c00
}

.border-left-orange-800 {
    border-left-color: #ef6c00
}

.border-right-orange-800 {
    border-right-color: #ef6c00
}

.alpha-brown {
    background-color: #efebe9
}

.text-brown {
    color: #795548
}

.text-brown[href]:focus, .text-brown[href]:hover {
    color: #61443a
}

.bg-brown {
    background-color: #795548
}

.border-brown {
    border-color: #795548
}

.border-top-brown {
    border-top-color: #795548
}

.border-bottom-brown {
    border-bottom-color: #795548
}

.border-left-brown {
    border-left-color: #795548
}

.border-right-brown {
    border-right-color: #795548
}

.text-brown-300 {
    color: #a1887f
}

.text-brown-300[href]:focus, .text-brown-300[href]:hover {
    color: #90746a
}

.bg-brown-300 {
    background-color: #a1887f
}

.border-brown-300 {
    border-color: #a1887f
}

.border-top-brown-300 {
    border-top-color: #a1887f
}

.border-bottom-brown-300 {
    border-bottom-color: #a1887f
}

.border-left-brown-300 {
    border-left-color: #a1887f
}

.border-right-brown-300 {
    border-right-color: #a1887f
}

.text-brown-400 {
    color: #8d6e63
}

.text-brown-400[href]:focus, .text-brown-400[href]:hover {
    color: #775c53
}

.bg-brown-400 {
    background-color: #8d6e63
}

.border-brown-400 {
    border-color: #8d6e63
}

.border-top-brown-400 {
    border-top-color: #8d6e63
}

.border-bottom-brown-400 {
    border-bottom-color: #8d6e63
}

.border-left-brown-400 {
    border-left-color: #8d6e63
}

.border-right-brown-400 {
    border-right-color: #8d6e63
}

.text-brown-600 {
    color: #6d4c41
}

.text-brown-600[href]:focus, .text-brown-600[href]:hover {
    color: #553b33
}

.bg-brown-600 {
    background-color: #6d4c41
}

.border-brown-600 {
    border-color: #6d4c41
}

.border-top-brown-600 {
    border-top-color: #6d4c41
}

.border-bottom-brown-600 {
    border-bottom-color: #6d4c41
}

.border-left-brown-600 {
    border-left-color: #6d4c41
}

.border-right-brown-600 {
    border-right-color: #6d4c41
}

.text-brown-700 {
    color: #5d4037
}

.text-brown-700[href]:focus, .text-brown-700[href]:hover {
    color: #452f29
}

.bg-brown-700 {
    background-color: #5d4037
}

.border-brown-700 {
    border-color: #5d4037
}

.border-top-brown-700 {
    border-top-color: #5d4037
}

.border-bottom-brown-700 {
    border-bottom-color: #5d4037
}

.border-left-brown-700 {
    border-left-color: #5d4037
}

.border-right-brown-700 {
    border-right-color: #5d4037
}

.text-brown-800 {
    color: #4e342e
}

.text-brown-800[href]:focus, .text-brown-800[href]:hover {
    color: #362420
}

.bg-brown-800 {
    background-color: #4e342e
}

.border-brown-800 {
    border-color: #4e342e
}

.border-top-brown-800 {
    border-top-color: #4e342e
}

.border-bottom-brown-800 {
    border-bottom-color: #4e342e
}

.border-left-brown-800 {
    border-left-color: #4e342e
}

.border-right-brown-800 {
    border-right-color: #4e342e
}

.alpha-grey {
    background-color: #fafafa
}

.text-grey {
    color: #777
}

.text-grey[href]:focus, .text-grey[href]:hover {
    color: #646464
}

.bg-grey {
    background-color: #777
}

.border-grey {
    border-color: #777
}

.border-top-grey {
    border-top-color: #777
}

.border-bottom-grey {
    border-bottom-color: #777
}

.border-left-grey {
    border-left-color: #777
}

.border-right-grey {
    border-right-color: #777
}

.text-grey-300 {
    color: #999
}

.text-grey-300[href]:focus, .text-grey-300[href]:hover {
    color: #868686
}

.bg-grey-100 {
    background-color: #eee
}

.bg-grey-300 {
    background-color: #999
}

.border-grey-300 {
    border-color: #999
}

.border-top-grey-300 {
    border-top-color: #999
}

.border-bottom-grey-300 {
    border-bottom-color: #999
}

.border-left-grey-300 {
    border-left-color: #999
}

.border-right-grey-300 {
    border-right-color: #999
}

.text-grey-400 {
    color: #888
}

.text-grey-400[href]:focus, .text-grey-400[href]:hover {
    color: #757575
}

.bg-grey-400 {
    background-color: #888
}

.border-grey-400 {
    border-color: #888
}

.border-top-grey-400 {
    border-top-color: #888
}

.border-bottom-grey-400 {
    border-bottom-color: #888
}

.border-left-grey-400 {
    border-left-color: #888
}

.border-right-grey-400 {
    border-right-color: #888
}

.text-grey-600 {
    color: #666
}

.text-grey-600[href]:focus, .text-grey-600[href]:hover {
    color: #535353
}

.bg-grey-600 {
    background-color: #666
}

.border-grey-600 {
    border-color: #666
}

.border-top-grey-600 {
    border-top-color: #666
}

.border-bottom-grey-600 {
    border-bottom-color: #666
}

.border-left-grey-600 {
    border-left-color: #666
}

.border-right-grey-600 {
    border-right-color: #666
}

.text-grey-700 {
    color: #555
}

.text-grey-700[href]:focus, .text-grey-700[href]:hover {
    color: #424242
}

.bg-grey-700 {
    background-color: #555
}

.border-grey-700 {
    border-color: #555
}

.border-top-grey-700 {
    border-top-color: #555
}

.border-bottom-grey-700 {
    border-bottom-color: #555
}

.border-left-grey-700 {
    border-left-color: #555
}

.border-right-grey-700 {
    border-right-color: #555
}

.text-grey-800 {
    color: #444
}

.text-grey-800[href]:focus, .text-grey-800[href]:hover {
    color: #313131
}

.bg-grey-800 {
    background-color: #444
}

.border-grey-800 {
    border-color: #444
}

.border-top-grey-800 {
    border-top-color: #444
}

.border-bottom-grey-800 {
    border-bottom-color: #444
}

.border-left-grey-800 {
    border-left-color: #444
}

.border-right-grey-800 {
    border-right-color: #444
}

.alpha-slate {
    background-color: #eceff1
}

.text-slate {
    color: #607d8b
}

.text-slate[href]:focus, .text-slate[href]:hover {
    color: #506974
}

.bg-slate {
    background-color: #607d8b
}

.border-slate {
    border-color: #607d8b
}

.border-top-slate {
    border-top-color: #607d8b
}

.border-bottom-slate {
    border-bottom-color: #607d8b
}

.border-left-slate {
    border-left-color: #607d8b
}

.border-right-slate {
    border-right-color: #607d8b
}

.text-slate-300 {
    color: #90a4ae
}

.text-slate-300[href]:focus, .text-slate-300[href]:hover {
    color: #7a929e
}

.bg-slate-300 {
    background-color: #90a4ae
}

.border-slate-300 {
    border-color: #90a4ae
}

.border-top-slate-300 {
    border-top-color: #90a4ae
}

.border-bottom-slate-300 {
    border-bottom-color: #90a4ae
}

.border-left-slate-300 {
    border-left-color: #90a4ae
}

.border-right-slate-300 {
    border-right-color: #90a4ae
}

.text-slate-400 {
    color: #78909c
}

.text-slate-400[href]:focus, .text-slate-400[href]:hover {
    color: #657d89
}

.bg-slate-400 {
    background-color: #78909c
}

.border-slate-400 {
    border-color: #78909c
}

.border-top-slate-400 {
    border-top-color: #78909c
}

.border-bottom-slate-400 {
    border-bottom-color: #78909c
}

.border-left-slate-400 {
    border-left-color: #78909c
}

.border-right-slate-400 {
    border-right-color: #78909c
}

.text-slate-600 {
    color: #546e7a
}

.text-slate-600[href]:focus, .text-slate-600[href]:hover {
    color: #445a63
}

.bg-slate-600 {
    background-color: #546e7a
}

.border-slate-600 {
    border-color: #546e7a
}

.border-top-slate-600 {
    border-top-color: #546e7a
}

.border-bottom-slate-600 {
    border-bottom-color: #546e7a
}

.border-left-slate-600 {
    border-left-color: #546e7a
}

.border-right-slate-600 {
    border-right-color: #546e7a
}

.text-slate-700 {
    color: #455a64
}

.text-slate-700[href]:focus, .text-slate-700[href]:hover {
    color: #35464d
}

.bg-slate-700 {
    background-color: #455a64
}

.border-slate-700 {
    border-color: #455a64
}

.border-top-slate-700 {
    border-top-color: #455a64
}

.border-bottom-slate-700 {
    border-bottom-color: #455a64
}

.border-left-slate-700 {
    border-left-color: #455a64
}

.border-right-slate-700 {
    border-right-color: #455a64
}

.text-slate-800 {
    color: #37474f
}

.text-slate-800[href]:focus, .text-slate-800[href]:hover {
    color: #273338
}

.bg-slate-800 {
    background-color: #37474f
}

.border-slate-800 {
    border-color: #37474f
}

.border-top-slate-800 {
    border-top-color: #37474f
}

.border-bottom-slate-800 {
    border-bottom-color: #37474f
}

.border-left-slate-800 {
    border-left-color: #37474f
}

.border-right-slate-800 {
    border-right-color: #37474f
}

.border-transparent {
    border-color: transparent !important
}

.border-top-transparent {
    border-top-color: transparent !important
}

.border-bottom-transparent {
    border-bottom-color: transparent !important
}

.border-left-transparent {
    border-left-color: transparent !important
}

.border-right-transparent {
    border-right-color: transparent !important
}

.border-light-alpha {
    border-color: rgba(255, 255, 255, .1)
}

.border-top-light-alpha {
    border-top-color: rgba(255, 255, 255, .1)
}

.border-bottom-light-alpha {
    border-bottom-color: rgba(255, 255, 255, .1)
}

.border-left-light-alpha {
    border-left-color: rgba(255, 255, 255, .1)
}

.border-right-light-alpha {
    border-right-color: rgba(255, 255, 255, .1)
}

.border-dark-alpha {
    border-color: rgba(0, 0, 0, .1)
}

.border-top-dark-alpha {
    border-top-color: rgba(0, 0, 0, .1)
}

.border-bottom-dark-alpha {
    border-bottom-color: rgba(0, 0, 0, .1)
}

.border-left-dark-alpha {
    border-left-color: rgba(0, 0, 0, .1)
}

.border-right-dark-alpha {
    border-right-color: rgba(0, 0, 0, .1)
}

.bg-dark-alpha {
    background-color: rgba(0, 0, 0, .2)
}

.bg-light-alpha {
    background-color: rgba(255, 255, 255, .1)
}
