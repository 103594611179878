.nav-sidebar .nav-link i {
    line-height: normal;
}

input[type="file"] {
    height: auto;
}

.help-block {
    color: #666;
    line-height: 1.5;
    display: block;
    margin-top: 5px;
}

.has-error .select2-selection--single,
.has-error .input-group-text,
.has-error .tox-tinymce,
.has-error .form-control {
    border-color: #f44336 !important;
}


img {
    max-width: 100%;
}


.image-deleteable {
    position: relative;
}

.image-deleteable .del {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #f44336;
    padding: 1px 4px;
    color: #fff !important;
    border-bottom-left-radius: 3px;
}

.sidebar-dark .nav-sidebar .nav-item.active .nav-link {
    color: #fff;
    background-color: rgba(0, 0, 0, .15);
}

.editable-buttons {
    margin-top: 5px;
}

.editable-buttons .btn {
    margin-right: 5px;
}

.btn-default {
    color: #fff;
    background-color: #324148
}

.btn-default:hover {
    color: #fff;
    background-color: #212a2f
}

.editable-click, a.editable-click, a.editable-click:hover {
    text-decoration: none;
    border-bottom: dashed 1px #0088cc;
}

.editable-empty {
    color: #c1c1c1 !important;
    font-style: italic;
}

.editable-bg-transition {
    color: #000 !important;
    background-color: #fa2112;
    -webkit-transition: background-color 1400ms ease-out;
    -moz-transition: background-color 1400ms ease-out;
    -o-transition: background-color 1400ms ease-out;
    -ms-transition: background-color 1400ms ease-out;
    transition: background-color 1400ms ease-out;
}

.editable-checklist label {
    justify-content: flex-start !important;
}

.editable-checklist label input {
    margin-right: 5px;
}

a.customer-status {
    cursor: pointer;
}

a.customer-status[data-value="0"]:active,
a.customer-status[data-value="0"]:hover,
a.customer-status[data-value="0"] {
    background-color: #fa2112;
    display: inline-block;
    padding: 2px 5px;
    color: #fff !important;
}

a.customer-status[data-value="1"]:active,
a.customer-status[data-value="1"]:hover,
a.customer-status[data-value="1"] {
    background-color: #009d00;
    display: inline-block;
    padding: 2px 5px;
    color: #fff !important;
}


.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.pagination li > * {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #324148;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pagination li.disabled > * {
    color: #999;
}

.pagination li.active > * {
    background-color: #324148;
    color: #fff;
}


.sidebar-dark {
    background-color: #2f313e !important;
    color: #fff;
}

.navbar-dark,
.sidebar-dark .nav-sidebar > .nav-item-open > .nav-link:not(.disabled), .sidebar-dark .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-open > .nav-link:not(.disabled), .sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item > .nav-link.active {
    /*background-color: #ec4e4f !important;*/
}


.btn-primary:hover,
.btn-primary:active,
.btn-primary {
    /*background-color: #ec4e4f !important;*/
    /*border-color: #ec4e4f !important;*/
}


.item-color .bar {
    display: flex;
    align-items: center;
    border-left: 5px solid;
    padding-left: 5px;
    font-size: 16px;
}

.amount {
    font-size: 18px;
    font-weight: 500;
}

.btn-shadow {
    border-radius: 5px;
    border: 2px solid #fff !important;
    -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.27);
    box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.27);
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    /*color: #fff;*/
}

.btn-shadow:hover {
    border-color: #fff !important;
}

.img-inprofile {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 180px;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #fff;
}

@media (max-width: 1499px) {
    .img-inprofile {
        position: static;
        display: block;
        margin: 0 auto 20px auto;
        /*width: 400px;*/
        max-width: 100%;
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 1000 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    overflow: auto;
}

.ReactModal__Content {
    padding: 0 !important;

    /*border: 0 !important;*/
}

.ReactModal__Content .modal-content {
    border: 0 !important;
    box-shadow: none !important;
}


@media (max-width: 767px) {
    .ReactModal__Content {
        width: 90% !important;
    }
}

.list-item li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /*line-height: 1;*/
}

.list-item li label {
    margin-bottom: 0;
    padding-left: 5px;
}

.list-item-inline {
    display: flex;
}

.list-item-inline li {
    margin-right: 10px;
}

.modal-header {
    padding-bottom: 20px !important;
    border-bottom: 1px solid #e9ecef !important;
}

.modal-footer {
    padding-top: 20px !important;
    border-top: 1px solid #e9ecef !important;
}

.modal-body .modal-footer {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.sidebar-light {
    border-right: 1px solid #d8d8d8 !important;
}


.calendar-table select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    height: calc(1.5385em + .875rem + 2px);
    padding: .3125rem .75rem !important;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5385;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ddd;
    border-radius: .1875rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-size: .75rem;
    line-height: 1.6667;
    border-radius: .125rem;
}
