body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #6a6b7a !important;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    display: block !important;

}

.react-datepicker-wrapper {
    display: block !important;
}

div.react-confirm-alert-overlay {
    z-index: 9999;
}

.trans-status {
    font-size: 0.7rem !important;
}

.table-header thead th {
    background-color: #105788;
    color: #fff;
}


.page-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
}


.lds-roller {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-icon {
    color: #fff;
    /*height: 200px;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

.table-sm td, .table-sm th {
    padding: 5px 8px !important;
}

.table-sm th {
    font-weight: bold !important;
    background-color: #2f313e;
    color: #fff;
}

.page-header-light {
    background-color: #2f313e !important;
    color: #fff;
    border-bottom: none !important;
}

.breadcrumb-line-light {
    background-color: #4a4c5c !important;
    color: #fff;
    border-color: transparent !important;
    border-bottom: none !important;
}

.bg-grey-100 {
    background-color: #6a6b7a !important;
}

.navbar-dark {
    background-color: #181920 !important;
}

a.breadcrumb-elements-item, a.breadcrumb-item {
    color: #fff !important;
}

.breadcrumb-item.active {
    color: #afaeae !important;
}

.content-wrapper .card {
    background-color: #4a4c5c !important;
    color: #fff;
}

.pagination li > * {
    background-color: #616779 !important;
    border: 1px solid #3e434c !important;
    color: #fff !important;
}

.pagination li.active > * {
    background-color: #2d2f38 !important;
}

.content-wrapper .card table td,
.content-wrapper .card table th {
    color: #fff;
}

.card-body + div[class*=table-responsive],
.content-wrapper .card .table td,
.content-wrapper .card .table thead th {
    border-color: #25262e !important;
}

.daterangepicker > div.ranges {
    display: none;
}


@media (max-width: 767px) {
    .header-elements-toggle {
        display: none;
    }

    .table-responsive .table {
        width: 200% !important;
        min-width: 1000px;
    }

    .header-elements-md-inline {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        flex-wrap: nowrap;
    }

    .sidebar.active .sidebar-content,
    .sidebar.active {
        left: 0 !important;
    }

    .card-body .form-inline .form-group {
        margin-right: 0 !important;
        width: 100%;
    }

    .card-body .form-inline .form-control,
    .card-body .form-inline .btn {
        width: 100% !important;
    }
}

.content-wrapper{
    min-height: 100vh;
}
